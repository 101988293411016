<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区医生工作中心</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <img :src="imgUrl.product" alt="">
          </div>
          <div class="text bg">
            <p>病区医生工作中心以医嘱执行与处方。医生学术会议支持系统、病区会诊中心、床旁医生工作站为核心系统，帮助病区提升并实现移动医疗、移动查房、病区学术中心、病区会诊中心、病区远程会诊中心等数据可视化和交互体验等数字化、便捷化的医疗服务功能。</p>
            <div class="js-list">
              <div class="tit">提供服务与功能</div>
              <ul>
                <li class="dot">学术会议支持系统</li>
                <li class="dot">病区会诊中心</li>
                <li class="dot">医生交接班中心</li>
                <li>院际会诊</li>
                <li class="dot">移动医疗系统</li>
                <li>院内会诊</li>
                <li class="dot">床旁医生工作站</li>
                <li class="long">远程会诊(视频会议+实时数据共享)</li>
                <li>床旁处方</li>
                <li>院外影像学会诊</li>
                <li>床旁会诊</li>
                <li>疑难病理会诊</li>
                <li>床旁处置</li>
                <li>普通病理会诊</li>
                <li>床旁医嘱执行检查</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>病例跟踪</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>医护沟通</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>远程会议</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>病理分析</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>学术支持</h3>
            </li>
            <li>
              <em class="p6"></em>
              <h3>临床医疗</h3>
            </li>
            <li>
              <em class="p7"></em>
              <h3>远程医疗</h3>
            </li>
            <li>
              <em class="p8"></em>
              <h3>医学影像</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'doctor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/banner-doctor.png')
    this.imgUrl.product = getAliyunImg.signatureUrl('ytwd/gw/text-doctor.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        product: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/banner-doctor.png");
  .icon {
    background-image: url("../../assets/images/banner3-icon.png");
  }
}
.content .product .details .img {
  height: 535px;
  display: flex;
  align-items: flex-end;
  background: none;
}
.js-list {
  line-height: 36px;
  margin-top: 15px;
  .tit {
    color: #00DCD4;
    font-weight: bold;
  }
  ul {
    overflow: hidden;
  }
  li {
    float: left;
    width: 240px;
    padding: 0 10px 0 15px;
    position: relative;
    // font-size: 16px;
    &.long {
      width: 275px;
      padding-right: 0;
    }
    &.dot:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #00DCD4;
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
}
</style>
